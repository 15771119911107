import "./style.scss";
import { useEffect, useState } from "react";
import { getUser, updateUserDetails } from "../../utils/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { country } from "../../constants";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Loader from "../../components/Loader";
import Select from "react-select";
import { getData } from "country-list";

interface updateDetailsForm {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  affiliatedBrands: [];
  city: string;
  countryResidence: string;
  userId: string | null;
}

const Profile: React.FC = () => {
  const [userName, setUserName] = useState<string>();
  const [isUpdated, setIsUpdated] = useState(false);
  const [isHide, setIsHide] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState<updateDetailsForm>({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    affiliatedBrands: [],
    city: "",
    countryResidence: "",
    userId: "",
  });

  useEffect(() => {
    setIsLoading(true);
    const token = sessionStorage.getItem("token");
    const userId = sessionStorage.getItem("userId");
    getUser(userId, token).then((res) => {
      setUserName(res.list.firstName + " " + res.list.lastName);
      setUserDetails({
        email: res.list.email,
        password: res.list.password,
        firstName: res.list.firstName,
        lastName: res.list.lastName,
        phoneNumber: res.list.phoneNumber,
        affiliatedBrands: res.list.affiliatedBrands,
        city: res.list.city,
        countryResidence: res.list.countryResidence,
        userId: sessionStorage.getItem("userId"),
      });
      setIsLoading(false);
    });
  }, [isUpdated]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setUserDetails({
      ...userDetails,
      [name]: value,
    });
  };

  const handleAffiliatedBands = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { value } = e.target;
    const newAffiliatedBrands: any = [...userDetails.affiliatedBrands];
    newAffiliatedBrands[index] = value;
    setUserDetails({
      ...userDetails,
      affiliatedBrands: newAffiliatedBrands,
    });
  };

  const handleUpdate = (e: any) => {
    e.preventDefault();
    const token = sessionStorage.getItem("token");
    updateUserDetails(userDetails, token).then((res) => {
      setIsUpdated(!isUpdated);
      toast.success("Profile updated successfully");
    });
  };

  const countryOptions = getData().map((country: any) => ({
    value: country.code,
    label: country.name,
  }));

  const customStyles = {
    container: (provided: any) => ({
      ...provided,
      width: "100%",
      borderRadius: "5px",
      marginTop: "10px",
    }),
    control: (provided: any) => ({
      ...provided,
      height: "3.5rem",
      backgroundColor: "#f2f2f2",
      borderWidth: "1px",
      border: "none",
      borderRadius: "5px",
      boxShadow: "none",
      textTransform: "capitalize",
      "&:hover": {
        borderColor: "#646467",
      },
    }),
    valueContainer: (provided: any) => ({
      ...provided,
    }),
    placeholder: (provided: any) => ({
      ...provided,
      fontSize: "1rem",
      fontWeight: "regular",
      color: "#666666",
    }),
    input: (provided: any) => ({
      ...provided,
      margin: "0",
      padding: "0",
    }),
    singleValue: (provided: any) => ({
      ...provided,
      fontSize: "1rem",
      textTransform: "capitalize",
      padding: "0 1rem"
    }),
    menu: (provided: any) => ({
      ...provided,
      borderRadius: "5px",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#f2f2f2" : "#fff",
      color: "#666666",
      textTransform: "capitalize",
      "&:hover": {
        backgroundColor: "#f2f2f2",
      },
    }),
  };

  return (
    <>
      <ToastContainer />
      <div className="userProfileContainer">
        <div className="title">
          <div className="heading">
            <h1>{userName}</h1>
            <p>Here is your profile. You can edit and save it.</p>
          </div>
        </div>
        <form className="layout" onSubmit={handleUpdate}>
          <div className="grid md:flex gap-5">
            <div className="input-container">
              <label htmlFor="">First Name</label>
              <input
                type="text"
                name="firstName"
                value={userDetails.firstName}
                onChange={handleChange}
                placeholder="First Name"
              />
            </div>
            <div className="input-container">
              <label htmlFor="">Last Name</label>
              <input
                type="text"
                name="lastName"
                value={userDetails.lastName}
                onChange={handleChange}
                placeholder="Last Name"
              />
            </div>
          </div>

          <div className="grid md:flex gap-5">
            <div className="w-full">
              <label htmlFor="">Email</label>
              <input
                type="text"
                name="firstName"
                value={userDetails.email}
                onChange={handleChange}
                placeholder="Email"
                disabled
              />
            </div>
            <div className="password-wrapper">
              <label htmlFor="">Password</label>
              <input
                type={isHide ? "password" : "text"}
                placeholder="Password"
                name="password"
                value={userDetails.password}
                onChange={handleChange}
              />
              <span
                className="hide_password"
                onClick={() => {
                  setIsHide(!isHide);
                }}
              >
                {!isHide ? <VisibilityOff /> : <Visibility />}
              </span>
            </div>
          </div>

          <div className="grid md:flex gap-5">
            <div className="input-container">
              <label htmlFor="">City</label>
              <input
                type="text"
                name="city"
                value={userDetails.city}
                onChange={handleChange}
                placeholder="City"
              />
            </div>
            <div className="input-container">
              <label htmlFor="">Country</label>
              <Select
                options={countryOptions}
                placeholder="Select a country"
                value={
                  countryOptions.find(
                    (option) => option.label === userDetails.countryResidence
                  ) || null
                }
                isSearchable
                onChange={(option) =>
                  handleChange({
                    target: {
                      name: "countryResidence",
                      value: option ? option.label : "",
                    },
                  })
                }
                styles={customStyles}
              />
            </div>
          </div>

          <div className="grid md:flex gap-5">
            <div className="w-full">
              <label htmlFor="">Affiliated Bands / Musical Groups</label>
              {userDetails.affiliatedBrands.map((value, index) => (
                <input
                  key={index}
                  type="text"
                  name={`affiliatedBrands-${index}`}
                  placeholder="Affiliated Bands / Musical Groups"
                  value={value}
                  onChange={(event) => handleAffiliatedBands(event, index)}
                />
              ))}
            </div>
          </div>

          <button className="delete-btn">Update</button>
        </form>
      </div>
      {isLoading && <Loader />}
    </>
  );
};

export default Profile;
