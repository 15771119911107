export const disclaimerText = `
  1. Content must be 100% original and created by you, and may not violate the right of privacy or publicity of, or constitute a defamation against, any person (living or deceased) or any entity.
  2. Content may not infringe upon or violate the trademark, copyright, or common law rights or any other rights of any person or entity, and there must not be any encumbrances, liens, conditions or restrictions whatsoever upon or affecting your right to license such Content or any portion of the Content.
  3. Content must not contain any third party owned or protected content.
  4. Content may not be subject to, or be within the parameters of, any performing arts union or guild. Under no circumstances will any compensation, such as payment, residual, royalty, reuse or similar payments, be payable to anyone by Our Music Hub, regardless of the manner and the extent to which the Our Music Hub elects to use the Content or any portion of the Content.
  5. Content must not include anything that promotes bigotry, racism, hatred or harm against any group or individual or promotes discrimination based on race, sex, religion, nationality, disability, sexual orientation or age.  We reserve the right to remove any and all submitted content at our sole and absolute discretion.
  6. If any person other than yourself appears in the Content, you acknowledge and agree that you have obtained, prior to uploading the Content, any and all releases and/or consents necessary to permit the display, exhibition, and other use of the Content or any portion of the content submitted.
  7. You may not be, nor may you work with any party in conjunction with any uploaded Content, who: (a) is represented under contract (e.g., by a talent agent or manager) that would limit or impair our ability to display the Content in any media form; (b) has a contract that would make appearance in the Content a violation of any third party rights; or (c) is under any other contractual relationship, including but not limited to guild and/or union memberships, that may prohibit use from using the Content in any form or format, royalty-free, worldwide, in all media in perpetuity.
  8. Content must not include any personally identifiable information about third parties (e.g., name, address, email address, phone number, etc.).
  9. Content must not contain any commercial or corporate advertising (such as corporate names, brand names, slogans, jingles or plugs).  Any such submissions will be removed and the responsible profile will be deleted and banned, at our sole discretion.  No exceptions.
  10. Content must be suitable for presentation in a public forum.
  11. There is no commitment or agreement by Our Music Hub to broadcast the product you submit.
  12. You understand and acknowledge that all Content published on the Site is available to be viewed by anyone with access to the Internet and that no Content is being submitted in confidence or in trust to this Site and no confidential or fiduciary relationship is intended or created.
  13. Our Music Hub does not return submitted content. You should keep a duplicate of any content submitted, as well as a copy of this Agreement. By this submission, you agree that you have retained a copy of such material, and that you release Our Music Hub (and each of Our Music Hub’s owners, directors, members, officers, employees and other representatives) from any liability for loss or other damage to the copy or copies submitted by you. You understand that in the unlikely event that Our Music Hub does return the files to you, such return of said content shall not terminate or affect any rights or obligations under this Agreement.
  
  Ownership of Content
  All Content publicly posted, uploaded and/or privately transmitted on or through this Site is your sole responsibility, not Our Music Hub. You retain all ownership rights over all Content, and you are solely responsible for all Content and the consequences of submitting, uploading or otherwise transmitting your Content to or through this Site.`;

export const genre = [
  "Alternative",
  "Blues",
  "Classical",
  "Country",
  "Dance",
  "Electronic",
  "Folk",
  "Hip Hop/Rap",
  "Industrial",
  "Jazz",
  "Metal",
  "New Age",
  "Pop",
  "Progressive",
  "R&B/Soul",
  "Reggae",
  "Rock",
  "Ska",
  "Traditional",
  "Vocal",
];

export const additionalLanguages = [
  'Filipino', // Add more as needed
];

export const formatDate = (isoDate: any) => {
  const date = new Date(isoDate);
  const mm = String(date.getUTCMonth() + 1).padStart(2, "0");
  const dd = String(date.getUTCDate()).padStart(2, "0");
  const yy = String(date.getUTCFullYear()).slice(-2);
  return `${mm}/${dd}/${yy}`;
};


export const formatDate1 = (date: any) => {
  const d = new Date(date);
  const month = (d.getMonth() + 1).toString().padStart(2, '0');
  const day = d.getDate().toString().padStart(2, '0');
  const year = d.getFullYear();
  return `${year}/${month}/${day}`;
};

export const formatDate2 = (date: any) => {
  const d = new Date(date);
  const month = (d.getMonth() + 1).toString().padStart(2, '0');
  const day = d.getDate().toString().padStart(2, '0');
  const year = d.getFullYear();
  return `${day}/${month}/${year}`;
};