import React, { useState } from "react";
import verify_banner from "../../assets/Pictures/verify_banner.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { userVerifiey } from "../../utils/auth";
import { Link, useParams } from "react-router-dom";
import backgroundImage from "../../assets/Pictures/background_image.png";
import successBanner from "../../assets/Pictures/success_banner.png";

const VerifyUser: React.FC = () => {
  const { userId } = useParams();
  const [isResetDone, setIsResetDone] = useState(false);

  const handleConfirm = () => {
    const body = {
      userId: userId,
    };
    userVerifiey(body).then((res: any) => {
        toast.success("User verified successfully");
        setIsResetDone(true)
    }).catch((err)=>{
        toast.error(err.response.data.message);
    })
  };

  return (
    <>
      <ToastContainer />
      {!isResetDone ? (
      <div className="forgotPwdContainer">
        <div className="wrapper sm:grid lg:flex">
          <div className="image_container xs-w-full sm:w-full md:w-full lg:w-full">
            <img src={verify_banner} alt="verify_banner" />
          </div>
          <div className="password_container xs-w-full sm:w-full md:w-full lg:w-full">
            <div>
              <h1>Verify your Email</h1>
              <p className="text">Click below to confirm your email.</p>
            </div>
            <button className="send-btn" onClick={handleConfirm}>
              Confirm
            </button>
          </div>
        </div>
      </div>
    ) : (
        <div className="backgroundImage">
          <img
            className="full-size"
            src={backgroundImage}
            alt="backgroundImage"
          />
          <div className="centered">
            <img src={successBanner} alt="successBanner" />
            <p className="text">Email verified</p>
           <Link to="/"><button className="send-btn">login now</button></Link>
          </div>
        </div>
      )}
    </>
  );
};

export default VerifyUser;
