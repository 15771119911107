import request from "./api";
import axios from "axios";

const url = process.env.REACT_APP_BASE_URL;

export const signUp = async (data: any) => {
  return await request("POST", {
    url: `${url}/api/signUp`,
    data,
  });
};

export const login = async (data: any) => {
  return await request("POST", {
    url: `${url}/api/login`,
    data,
  });
};

export const forgotPassword = async (data: any) => {
  return await request("POST", {
    url: `${url}/api/forgotpassword`,
    data,
  });
};

export const uploadTrack = async (formData: FormData, token: string) => {
  try {
    const response = await axios.post(`${url}/api/createTracks`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getTracksById = async (userId: any, token: any) => {
  try {
    const response = await axios.get(
      `${url}/api/getTracksById?userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getUserDetails = async (userId: any, token: any) => {
  try {
    const response = await axios.get(
      `${url}/api/getUserDetails?userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getUser = async (userId: any, token: any) => {
  try {
    const response = await axios.get(
      `${url}/api/getUser?userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateUserDetails = async (data: any, token: any) => {
  try {
    const response = await axios.put(`${url}/api/updateUserDetails`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllTracks = async (data: any, token: any) => {
  try {
    const response = await axios.post(`${url}/api/getAllTracks`,data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const resendVerfication = async (data: any) => {
  try {
    const response = await axios.post(`${url}/api/resendVerfication`, data);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const userVerifiey = async (data: any) => {
  try {
    const response = await axios.put(`${url}/api/userVerifiey`, data);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async (data: any) => {
  try {
    const response = await axios.put(`${url}/api/resetPassword`, data);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteTracks = async (trackId: string, token: any) => {
  try {
    const response = await axios.delete(`${url}/api/deleteTracks?trackId=${trackId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteUser = async (userId: string, token: any) => {
  try {
    const response = await axios.delete(`${url}/api/deleteUser?userId=${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const loginWithEmail = async (data: any) => {
  try {
    const response = await axios.post(`${url}/api/loginwithEmail`,data);

    return response.data;
  } catch (error) {
    throw error;
  }
};
