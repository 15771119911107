import * as React from "react";
import {
  Modal,
  ModalDialog,
} from "@mui/joy";
import { CircularProgress } from "@mui/material";

const Loader: React.FC = () => {
  return (
    <React.Fragment>
      <Modal open={true}>
        <ModalDialog variant="outlined" role="alertdialog">
          <div className="m-auto">
            <CircularProgress color="secondary" />
          </div>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
};

export default Loader;
