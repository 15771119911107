import React from "react";
import verify_banner from "../../assets/Pictures/verify_banner.png";
import { resendVerfication } from "../../utils/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface props{
  email: string
}

const EmailVerify: React.FC<props> = ({email}) => {

  const handleResend = () => {
    const data = {
      email: email
    }
    resendVerfication(data).then((res)=>{
      toast.success("Email sent successfully");
    }).catch((err)=>{
      toast.error(err.response.data.message);
    })
  }

  const formatEmail= (email: string)=> {
    const [localPart, domain] = email.split("@");
    if (localPart.length > 2) {
      const formattedLocalPart = localPart.slice(0, 2) + "****";
      return `${formattedLocalPart}@${domain}`;
    }
    return email;
  }

  return (
    <>
    <ToastContainer/>
        <div className="forgotPwdContainer">
          <div className="wrapper sm:grid lg:flex">
            <div className="image_container xs-w-full sm:w-full md:w-full lg:w-full">
              <img src={verify_banner} alt="verify_banner" />
            </div>

            <div className="password_container xs-w-full sm:w-full md:w-full lg:w-full">
              <div>
                <h1>Verify your Email</h1>
                <p className="text">We have sent a verification email to {formatEmail(email)}.</p>
              </div>

              <p className="text">Didn't receive the email? Check spam or promotion folder</p>
              <button className="send-btn" onClick={handleResend}>resend email</button>
            </div>
          </div>
        </div>
    </>
  );
};

export default EmailVerify;
