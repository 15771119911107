import React, { useEffect, useState } from "react";
import "./style.scss";
import { Link, useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import EmailVerify from "../../components/EmailVerify";
import addIcon from "../../assets/icons/PlusCircle.svg";
import { loginWithEmail, signUp } from "../../utils/auth";
import deleteIcon from "../../assets/icons/Trash.svg";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { getData } from "country-list";

interface signUpForm {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  city: string;
  affiliatedBrands: any[];
  countryResidence: string;
  isAgree: boolean;
}

interface DecodedToken {
  iss: string;
  azp: string;
  aud: string;
  sub: string;
  hd: string;
  email: string;
  email_verified: boolean;
  exp: number;
  family_name: string;
  given_name: string;
  iat: number;
  name: string;
  picture: string;
}

const Signup: React.FC = () => {
  const navigate = useNavigate();
  const [isHide, setIsHide] = useState(true);
  const [isSignUp, setIsSignUp] = useState(false);
  const [inputs, setInputs] = useState([""]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [formData, setFormData] = useState<signUpForm>({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    mobileNumber: "",
    city: "",
    affiliatedBrands: [""],
    countryResidence: "",
    isAgree: false,
  });

  useEffect(() => {
    const isValid =
      formData.email &&
      formData.password.length >= 6 &&
      formData.firstName &&
      formData.lastName &&
      formData.mobileNumber.length >= 10 &&
      formData.city &&
      formData.affiliatedBrands.length &&
      formData.countryResidence &&
      formData.isAgree;

    isValid ? setIsFormValid(true) : setIsFormValid(false);
  }, [formData]);

  const handleChange = (e: any) => {
    const { name, value, type, checked } = e.target;
    if (name === "mobileNumber") {
      if (/^\d*$/.test(value)) {
        setFormData({ ...formData, [name]: value });
      }
    } else {
      setFormData({
        ...formData,
        [name]: type === "checkbox" ? checked : value,
      });
    }
  };

  const handleBandChange = (index: number, value: any) => {
    const newBands = [...formData.affiliatedBrands];
    newBands[index] = value;
    setFormData({ ...formData, affiliatedBrands: newBands });
  };

  const handleAddInput = () => {
    if (inputs.length < 5) {
      setInputs([...inputs, ""]);
    }
  };

  const handleDeleteInput = (index: number) => {
    if (inputs.length > 1) {
      const newInputs = [...inputs];
      newInputs.splice(index, 1);
      setInputs(newInputs);

      const newBands = [...formData.affiliatedBrands];
      newBands.splice(index, 1);
      setFormData({ ...formData, affiliatedBrands: newBands });
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    signUp(formData)
      .then((res) => {
        setIsSignUp(true);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const handleSignupSuccess = (res: any) => {
    const decoded: DecodedToken = jwtDecode(res.credential);
    const data = {
      email: decoded.email,
      password: "",
      firstName: decoded.given_name,
      lastName: decoded.family_name,
      phoneNumber: "",
      city: "",
      isVerified: decoded.email_verified,
    };

    signUp(data).then((res) => {
      const data = {
        email: decoded.email,
      };
      loginWithEmail(data).then((res) => {
        sessionStorage.setItem("token", res.token);
        sessionStorage.setItem("userId", res._id);
        sessionStorage.setItem("role", res.role);
        if (res.role === "A") {
          navigate("/admin-dashboard");
        } else {
          navigate("/upload-track");
        }
      });
    });
  };

  const handleError = (err: any) => {
    console.log("Login Error:", err);
  };

  const countryOptions = getData().map((country: any) => ({
    value: country.code,
    label: country.name,
  }));

  return (
    <>
      <ToastContainer />
      {!isSignUp ? (
        <div className="SignupContainer">
          <div className="title">
            <h1 className="welcome_text">Create Account</h1>
            <p className="text">
              Already have account?{" "}
              <Link to="/">
                <span>Login</span>
              </Link>
            </p>
          </div>

          <form onSubmit={handleSubmit} className="layout">
            <div className="grid md:flex gap-5">
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                value={formData.firstName}
                onChange={handleChange}
              />
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleChange}
              />
            </div>
            <div className="grid md:flex gap-5">
              <input
                type="text"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
              />
              <PhoneInput
                country={"us"}
                value={formData.mobileNumber}
                onChange={(value) =>
                  handleChange({ target: { name: "mobileNumber", value } })
                }
                inputStyle={{
                  width: "100%",
                  height: "3.5rem",
                  border: "1px solid #646467",
                  borderRadius: "5px",
                  backgroundColor: "#fff",
                  fontSize: "clamp(1rem, 3vw, 1.25rem)",
                  fontWeight: "regular",
                  color: "#666666",
                  outline: "none",
                  padding: "0 3rem"
                }}
                buttonStyle={{
                  background: "none",
                  border: "none",
                  outline: "none",
                }}
              />
            </div>
            <div className="grid md:flex gap-5">
              <select
                name="countryResidence"
                value={formData.countryResidence}
                onChange={handleChange}
              >
                <option value="">Country</option>
                {countryOptions.map((ele: any, index: number) => (
                  <option key={index} value={ele.label}>
                    {ele.label}
                  </option>
                ))}
              </select>
              <input
                type="text"
                name="city"
                placeholder="City"
                value={formData.city}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col gap-2">
              {inputs.map((input, index) => (
                <div key={index} className="musical-groups">
                  <input
                    type="text"
                    placeholder="Affiliated Bands / Musical Groups"
                    value={formData.affiliatedBrands[index]}
                    onChange={(e) => handleBandChange(index, e.target.value)}
                  />
                  {index === inputs.length - 1 && index > 0 && (
                    <img
                      src={deleteIcon}
                      alt="deleteIcon"
                      onClick={() => handleDeleteInput(index)}
                    />
                  )}
                  {index === inputs.length - 1 && inputs.length < 5 && (
                    <img src={addIcon} alt="addIcon" onClick={handleAddInput} />
                  )}
                </div>
              ))}
              <span className="relative text-xs">
                Spelling is important for linking members and fans.
              </span>
            </div>
            <div className="password-wrapper">
              <input
                type={isHide ? "password" : "text"}
                name="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleChange}
              />
              <span
                className="hide_password"
                onClick={() => {
                  setIsHide(!isHide);
                }}
              >
                {!isHide ? <VisibilityOff /> : <Visibility />}
              </span>
              {formData.password.length > 0 && formData.password.length < 6 && (
                <span className="absolute top-full text-red-600">
                  Password must be at least 6 characters
                </span>
              )}
            </div>

            <div className="flex gap-3 lg:items-center">
              <input
                type="checkbox"
                name="isAgree"
                checked={formData.isAgree}
                onChange={handleChange}
              />
              <p>
                I agree to Our Music Hub <span>Terms of service </span>and{" "}
                <span>Privacy policy</span>
              </p>
            </div>
            <button
              className="signup-btn"
              disabled={!isFormValid}
              style={
                !isFormValid ? { opacity: "0.5", cursor: "not-allowed" } : {}
              }
            >
              Sign up
            </button>
          </form>

          <div className="line_break">
            <hr />
            <span>or</span>
          </div>

          <div className="footer">
            <GoogleLogin
              onSuccess={handleSignupSuccess}
              onError={() => handleError}
            />
          </div>
        </div>
      ) : (
        <>
          <EmailVerify email={formData.email} />
        </>
      )}
    </>
  );
};

export default Signup;
