import React from "react";
import { Outlet } from "react-router-dom";
import SideMenu from "../../components/SideMenu";

const Layout: React.FC = () => {
  return (
    <div className="flex">
      <SideMenu />
       <main className="w-full">
        <Outlet />
      </main>
    </div>
  );
};

export default Layout;
