import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./pages/login";
import Signup from "./pages/signup";
import ForgotPassword from "./pages/forgot-password";
import ResetPassword from "./pages/reset-password";
import UploadTrack from "./pages/upload-track";
import ProtectedRoute from "./components/protectedRoute";
import PublicRoute from "./components/publicRoute";
import Layout from "./pages/layout";
import Submissions from "./pages/submissions";
import AdminDashboard from "./pages/admin-dashboard";
import UserProfile from "./pages/user-profile/[id]";
import Profile from "./pages/profile";
import VerifyUser from "./pages/verify-user";
import ProfileHistory from "./pages/profile-history";
import DeletedTracks from "./pages/deleted-tracks";

const RedirectBasedOnAuth: React.FC = () => {
  const token = sessionStorage.getItem("token");
  const role = sessionStorage.getItem("role");

  if (token) {
    return role === "A" ? (
      <Navigate to="/admin-dashboard" />
    ) : (
      <Navigate to="/upload-track" />
    );
  }
  return <Navigate to="/" />;
};

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="*" element={<RedirectBasedOnAuth />} />

          <Route path="/" element={<PublicRoute><Login /></PublicRoute>} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:userId?/:token?" element={<ResetPassword />} />
          <Route path="/verify-user/:userId?" element={<VerifyUser />} />

          {/* {Protected Admin routes} */}
          <Route path="/" element={<ProtectedRoute requiredRole="A"><Layout /></ProtectedRoute>}>
          <Route path="admin-dashboard" element={<ProtectedRoute><AdminDashboard /></ProtectedRoute>} />
          <Route path="profile-history" element={<ProtectedRoute><ProfileHistory /></ProtectedRoute>} />
          <Route path="deleted-tracks" element={<ProtectedRoute><DeletedTracks /></ProtectedRoute>} />
          <Route path="user-profile/:userId" element={<ProtectedRoute><UserProfile /></ProtectedRoute>} />
          </Route>

          {/* {Protected Musician routes} */}
          <Route path="/" element={<ProtectedRoute requiredRole="M"><Layout /></ProtectedRoute>}>
            <Route path="upload-track" element={<ProtectedRoute><UploadTrack /></ProtectedRoute>}/>
            <Route path="submissions" element={<ProtectedRoute><Submissions /></ProtectedRoute>}/>
            <Route path="profile" element={<ProtectedRoute><Profile /></ProtectedRoute>}/>
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
