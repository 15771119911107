import React, { useState, useEffect } from "react";
import "./style.scss";
import ClockCount from "../../assets/icons/ClockCount.svg";
import upload from "../../assets/icons/Upload.svg";
import profile from "../../assets/icons/profile.svg";
import logoutIcon from "../../assets/icons/logout.svg";
import { useNavigate, useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FolderDeleteIcon from "@mui/icons-material/FolderDelete";
import AccountBoxIcon from "@mui/icons-material/AccountBox";

const SideMenu: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [menu, setMenu] = useState("upload");
  const [isOpen, setIsOpen] = useState(false);
  const userRole = sessionStorage.getItem("role");

  useEffect(() => {
    const path = location.pathname;
    if (path === "/admin-dashboard" || path.startsWith("/user-profile/")) {
      setMenu("dashboard");
    } else if (path === "/profile-history") {
      setMenu("profile");
    } else if (path === "/deleted-tracks") {
      setMenu("deletedTracks");
    } else if (path === "/upload-track") {
      setMenu("upload");
    } else if (path === "/submissions") {
      setMenu("submission");
    } else if (path === "/profile") {
      setMenu("profile");
    } else {
      setMenu("upload");
    }
  }, [location.pathname]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleRoute = (route: string, menuName: string) => {
    if (menuName === "logout") {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("userId");
      sessionStorage.removeItem("role");
      navigate("/");
    } else {
      setMenu(menuName);
      setIsOpen(false);
      navigate(route);
    }
  };

  return (
    <>
      <div className="topHeader">
        <div className="menuToggle" onClick={toggleMenu}>
          {isOpen ? <CloseIcon /> : <MenuIcon />}
        </div>
      </div>

      <div className={`SideMenuBar ${isOpen ? "open" : ""}`}>
        <ul>
          {userRole === "A" && (
            <>
              <li
                className={menu === "dashboard" ? "active" : ""}
                onClick={() => handleRoute("/admin-dashboard", "dashboard")}
              >
                <DashboardIcon />
                Dashboard
              </li>
              <li
                className={menu === "profile" ? "active" : ""}
                onClick={() => handleRoute("/profile-history", "profile")}
              >
                <AccountBoxIcon />
                Profile History
              </li>
              <li
                className={menu === "deletedTracks" ? "active" : ""}
                onClick={() => handleRoute("/deleted-tracks", "deletedTracks")}
              >
                <FolderDeleteIcon />
                Deleted tracks
              </li>
            </>
          )}
          {userRole === "M" && (
            <>
              <li
                className={menu === "upload" ? "active" : ""}
                onClick={() => handleRoute("/upload-track", "upload")}
              >
                <img src={upload} alt="upload" /> Upload Track
              </li>
              <li
                className={menu === "submission" ? "active" : ""}
                onClick={() => handleRoute("/submissions", "submission")}
              >
                <img src={ClockCount} alt="ClockCount" /> Your Submissions
              </li>
              <li
                className={menu === "profile" ? "active" : ""}
                onClick={() => handleRoute("/profile", "profile")}
              >
                <img src={profile} alt="profile" /> Profile
              </li>
            </>
          )}
          <li
            className={menu === "logout" ? "active" : ""}
            onClick={() => handleRoute("/", "logout")}
          >
            <img src={logoutIcon} alt="logout" /> Logout
          </li>
        </ul>
      </div>
    </>
  );
};

export default SideMenu;
