import filter from "../../assets/icons/filter.svg";


const DeletedTracks: React.FC = () => {
  return (
    <>
      <div className="dashboardContainer">
        <div className="title">
          <div className="heading">
            <h1>Deleted Tracks History</h1>
            <h3>Here are Deleted Tracks history.</h3>
          </div>
        </div>

        <div className="wrapper">
          <div className="flex justify-end">
            <div className="filter">
              <button className="filter-btn">
                <img src={filter} alt="filter" /> Filter
              </button>
            </div>
          </div>
          <div className="table-container">
            {/* <table className="responsive-table">
              <thead>
                <tr>
                  <th>User Profile</th>
                  <th>Email Id</th>
                  <th>Date</th>
                </tr>
              </thead>
            </table> */}
            <div className="h-[200px] flex justify-center items-center">
                <h1 className="text-2xl font-bold">Coming soon ...</h1>
              </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeletedTracks;
