import React, { useEffect, useState } from "react";
import "./style.scss";
import { deleteTracks, getTracksById } from "../../utils/auth";
import { formatDate } from "../../constants";
import Loader from "../../components/Loader";
import trash from "../../assets/icons/Trash.svg";
import DeleteModal from "../../components/DeleteModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Submissions: React.FC = () => {
  const token = sessionStorage.getItem("token");
  const userId = sessionStorage.getItem("userId");
  const [tracks, setTracks] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [trackId, setTrackId] = useState<any>();

  useEffect(() => {
    setIsLoading(true);
    getTracksById(userId, token).then((res) => {
      setTracks(res.list);
      setIsLoading(false);
    });
  }, [userId, token]);

  const handleDelete = () => {
    deleteTracks(trackId, token)
      .then((res) => {
        setIsDelete(false);
        const updatedArray = tracks.filter(
          (object: { _id: any }) => object._id !== trackId
        );
        setTracks(updatedArray);
        toast.success("Track deleted successfully");
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <>
    <ToastContainer />
      <div className="submissionContainer">
        <div className="title">
          <h1>Your Submissions History</h1>
          <h3>
            Here are the overall tracks that you have uploaded, Note: You can
            only upload 10 Tracks MP3.
          </h3>
        </div>

        <div className="wrapper">
          <div className="table-container">
            <table className="responsive-table">
              <thead>
                <tr>
                  <th>Track Name</th>
                  <th>Album Name</th>
                  <th className="!text-center">Genre</th>
                  <th className="!text-center">Upload Date</th>
                  <th className="!text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {tracks.map((track: any, index: number) => (
                  <tr key={index}>
                    <td>{track?.trackName}</td>
                    <td>{track.albumName}</td>
                    <td className="!text-center">{track.selectGenre}</td>
                    <td className="!text-center">
                      {formatDate(track.createdAt)}
                    </td>
                    <td>
                      <div className="flex flex-row gap-5 justify-center cursor-pointer">
                        <img
                          className="w-7"
                          src={trash}
                          alt="trash"
                          onClick={() => {
                            setTrackId(track._id);
                            setIsDelete(true);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {!tracks.length && (
              <div className="h-[200px] flex justify-center items-center">
                <h1 className="text-2xl font-bold">No data found</h1>
              </div>
            )}
          </div>
        </div>
      </div>
      {isDelete && (
        <DeleteModal
          onClose={() => setIsDelete(false)}
          onYes={handleDelete}
          message={
            "Hello there, are you sure you want to remove this track from the list?"
          }
        />
      )}
      {isLoading && <Loader />}
    </>
  );
};

export default Submissions;
