import React, { useState } from "react";
import banner from "../../assets/Pictures/resetPassword-banner.png";
import backgroundImage from "../../assets/Pictures/background_image.png";
import successBanner from "../../assets/Pictures/success_banner.png";
import { Link, useParams } from "react-router-dom";
import { resetPassword } from "../../utils/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface resetPwdForm {
  newPassword: string;
  confirmNewPassword: string;
}

const ResetPassword: React.FC = () => {
  const { userId, token } = useParams();
  const [isResetDone, setIsResetDone] = useState(false);
  const [resetForm, setResetForm] = useState<resetPwdForm>({
    newPassword: "",
    confirmNewPassword: "",
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setResetForm({
      ...resetForm,
      [name]: value,
    });
  };

  const handleResetPassword = () => {
    if (resetForm.newPassword === resetForm.confirmNewPassword) {
      const body = {
        userId: userId,
        password: resetForm.newPassword,
        referenceToken: token,
      };
      resetPassword(body).then((res: any) => {});
      setIsResetDone(true);
    }
    else{
      toast.error('password should be same')
    }
  };

  return (
    <>
    <ToastContainer/>
      {!isResetDone ? (
        <div className="forgotPwdContainer">
          <div className="wrapper sm:grid lg:flex">
            <div className="image_container w-full">
              <img src={banner} alt="banner" />
            </div>

            <div className="password_container w-full">
              <div>
                <h1>Reset Password</h1>
                <p className="text">Choose a new password for your account</p>
              </div>

              <input
                type="text"
                placeholder="Create New Password"
                name="newPassword"
                value={resetForm.newPassword}
                onChange={handleChange}
              />
              <input
                type="text"
                placeholder="Confirm New Password"
                name="confirmNewPassword"
                value={resetForm.confirmNewPassword}
                onChange={handleChange}
              />

              <button className="send-btn" onClick={handleResetPassword}>
                reset password
              </button>
              <Link to="/">
                <button className="backToLogin">Back to login</button>
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className="backgroundImage">
          <img
            className="full-size"
            src={backgroundImage}
            alt="backgroundImage"
          />
          <div className="centered">
            <img src={successBanner} alt="successBanner" />
            <p className="text">Password reset successfully</p>
            <Link to="/">
              <button className="send-btn">back to login</button>
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default ResetPassword;
