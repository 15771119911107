import { Navigate } from "react-router-dom";

const PublicRoute = ({ children }: { children: JSX.Element }) => {
  const token = sessionStorage.getItem("token");
  const role = sessionStorage.getItem("role");
  if (token) {
  return role === 'A' ? <Navigate to="/admin-dashboard" /> : <Navigate to="/upload-track" />;
  }
  else{
    return children;
  }
};

export default PublicRoute;
