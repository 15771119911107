import * as React from 'react';
import {Button, Divider, DialogTitle, DialogContent, DialogActions, Modal, ModalDialog} from '@mui/joy';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

interface props {
    onClose: () => void
    onYes: () => void
    message: string
}

const DeleteModal: React.FC<props> = ({onClose, onYes, message}) => {
  
  return (
    <React.Fragment>
      <Modal open={true} onClose={() => onClose}>
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <WarningRoundedIcon />
            Delete
          </DialogTitle>
          <Divider />
          <DialogContent>
            {message}
          </DialogContent>
          <DialogActions>
            <Button variant="solid" style={{ backgroundColor: '#e26639', color: 'white' }} onClick={()=>{onYes()}}>
              Yes
            </Button>
            <Button variant="plain" color="neutral" onClick={()=>{onClose()}}>
              No
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}

export default DeleteModal;