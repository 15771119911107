import React, { useState } from "react";
import "./style.scss";
import pwdBanner from "../../assets/Pictures/password-banner.png";
import { Link } from "react-router-dom";
import { forgotPassword } from "../../utils/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"

interface form {
  email: string;
}

const ForgotPassword: React.FC = () => {
  const [formData, setFormData] = useState<form>({
    email: "",
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleForgotPwd = () => {
    forgotPassword(formData)
      .then((res) => {
        toast.success("Link sent to registered email address");
      })
      .catch((err) => {
        toast.error("Failed to send link. Please try again.");
      });
  };

  return (
    <>
      <ToastContainer />
      <div className="forgotPwdContainer">
        <div className="wrapper sm:grid lg:flex">
          <div className="image_container w-full">
            <img src={pwdBanner} alt="" />
          </div>

          <div className="password_container w-full">
            <div>
              <h1>Forgot Password</h1>
              <p className="text">
                Enter the email you used to create your account so we can send
                you instructions on how to reset your password.
              </p>
            </div>
            <input
              type="text"
              placeholder="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />

            <button className="send-btn" onClick={handleForgotPwd}>
              send
            </button>
            <Link to="/">
              <button className="backToLogin">Back to login</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
