import * as React from "react";
import "./style.scss";
import {
  Button,
  DialogTitle,
  DialogActions,
  Modal,
  ModalDialog,
} from "@mui/joy";
import { genre } from "../../constants";
import arrow from "../../assets/icons/arrow_down.svg";
import { Divider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  onClose: () => void;
  onApply: (filters: { genres: string[]; locations: string[]; language: string[]; dates: any}) => void;
  appliedFilters: any;
}

const FilterModal: React.FC<Props> = ({ onClose, onApply, appliedFilters }) => {
  const [isGenre, setIsGenre] = React.useState(false);
  const [isLocation, setIsLocation] = React.useState(false);
  const [isLanguage, setIsLanguage] = React.useState(false);
  const [selectedGenres, setSelectedGenres] = React.useState<string[]>(
    appliedFilters.genres
  );
  const [selectedLocations, setSelectedLocations] = React.useState<string[]>(
    appliedFilters.locations
  );
  const [selectedLanguage, setSelectedLanguage] = React.useState<string[]>(
    appliedFilters.language
  );
  const [selectedDates, setSelectedDates] = React.useState<{ startDate?: any; endDate?: any }>(appliedFilters.dates);

  const handleGenreChange = (name: string) => {
    setSelectedGenres((prev) =>
      prev.includes(name) ? prev.filter((g) => g !== name) : [...prev, name]
    );
  };

  const handleLocationChange = (name: string) => {
    setSelectedLocations((prev) =>
      prev.includes(name) ? prev.filter((l) => l !== name) : [...prev, name]
    );
  };

  const handleLanguageChange = (name: string) => {
    setSelectedLanguage((prev) =>
      prev.includes(name) ? prev.filter((l) => l !== name) : [...prev, name]
    );
  };

  const handleDatesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSelectedDates((prevDates) => ({
      ...prevDates,
      [name]: value,
    }));
  };

  const genreStyle = isGenre ? "rotate(180deg)" : "rotate(0deg)";
  const locationStyle = isLocation ? "rotate(180deg)" : "rotate(0deg)";
  const languageStyle = isLanguage ? "rotate(180deg)" : "rotate(0deg)";

  return (
    <React.Fragment>
      <Modal open={true} onClose={onClose}>
        <ModalDialog
          variant="outlined"
          role="alertdialog"
          sx={{
            width: "50%",
            maxWidth: "600px",
            margin: "auto",
            overflowX: "hidden",
          }}
        >
          <p
            className="flex justify-end cursor-pointer"
            onClick={() => {
              onClose();
            }}
          >
            <CloseIcon />
          </p>

          <h1 className="text-xl font-bold">
            Choose the option you like to see on the screen!!
          </h1>
          <div className="filter-wrapper">
            <div className="datePicker">
              <div className="input-date">
                <label htmlFor="startDate">From</label>
                <input type="date" name="startDate" value={selectedDates.startDate} onChange={handleDatesChange}/>
              </div>
              <div className="input-date">
                <label htmlFor="endDate">To</label>
                <input type="date" name="endDate" disabled={!selectedDates.startDate} min={selectedDates.startDate || ""} value={selectedDates.endDate} onChange={handleDatesChange}/>
              </div>
            </div>
          </div>
          <div className="filter-wrapper">
            <label htmlFor="genre">Choose Genre</label>
            <p className="input-box" onClick={() => setIsGenre(!isGenre)}>
              <p>Genre</p>
              <img
                src={arrow}
                alt="arrow"
                style={{
                  transform: genreStyle,
                  transition: "transform 0.3s ease",
                }}
              />
            </p>
            {isGenre && (
              <div className="input-field">
                {genre.map((name: string) => (
                  <p key={name}>
                    <input
                      type="checkbox"
                      checked={selectedGenres.includes(name)}
                      onChange={() => handleGenreChange(name)}
                    />
                    {name}
                  </p>
                ))}
              </div>
            )}
          </div>
          {/* <div className="filter-wrapper">
            <label htmlFor="location">Choose Location</label>
            <p className="input-box" onClick={() => setIsLocation(!isLocation)}>
              <p>Location</p>
              <img
                src={arrow}
                alt="arrow"
                style={{
                  transform: locationStyle,
                  transition: "transform 0.3s ease",
                }}
              />
            </p>
            {isLocation && (
              <div className="input-field open">
                {country.map((name: string) => (
                  <p key={name}>
                    <input
                      type="checkbox"
                      checked={selectedLocations.includes(name)}
                      onChange={() => handleLocationChange(name)}
                    />
                    {name}
                  </p>
                ))}
              </div>
            )}
          </div>
          <div className="filter-wrapper">
            <label htmlFor="location">Choose Language</label>
            <p className="input-box" onClick={() => setIsLanguage(!isLanguage)}>
              <p>Language</p>
              <img
                src={arrow}
                alt="arrow"
                style={{
                  transform: languageStyle,
                  transition: "transform 0.3s ease",
                }}
              />
            </p>
            {isLanguage && (
              <div className="input-field open">
                {language.map((name: string) => (
                  <p key={name}>
                    <input
                      type="checkbox"
                      checked={selectedLanguage.includes(name)}
                      onChange={() => handleLanguageChange(name)}
                    />
                    {name}
                  </p>
                ))}
              </div>
            )}
          </div> */}
          <Divider />
          <DialogActions>
            <Button
              variant="solid"
              style={{ backgroundColor: "#e26639", color: "white" }}
              onClick={() =>
                onApply({
                  genres: selectedGenres,
                  locations: selectedLocations,
                  language: selectedLanguage,
                  dates: selectedDates
                })
              }
            >
              Apply
            </Button>
            <Button
              variant="plain"
              style={{
                backgroundColor: "#fff",
                color: "#e26639",
                border: "1px solid #e26639",
              }}
              onClick={() => {
                setSelectedGenres([]);
                setSelectedLocations([]);
                setSelectedLanguage([]);
                setSelectedDates({ startDate: "", endDate: "" });
              }}
            >
              Clear
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
};

export default FilterModal;
