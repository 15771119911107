import React, { useState } from "react";
import "./style.scss";
// import appleLogo from "../../assets/icons/Apple_logo.svg";
// import googleLogo from "../../assets/icons/Google_logo.svg";
import { Link } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { login, loginWithEmail } from "../../utils/auth";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";

interface loginForm {
  email: string;
  password: string;
}

interface DecodedToken {
  iss: string;
  azp: string;
  aud: string;
  sub: string;
  hd: string;
  email: string;
  email_verified: boolean;
  exp: number;
  family_name: string;
  given_name: string;
  iat: number;
  name: string;
  picture: string;
}

const Login: React.FC = () => {
  const navigate = useNavigate();
  const [isHide, setIsHide] = useState(true);
  const [formData, setFormData] = useState<loginForm>({
    email: "",
    password: "",
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    login(formData)
      .then((res) => {
        sessionStorage.setItem("token", res.token);
        sessionStorage.setItem("userId", res._id);
        sessionStorage.setItem("role", res.role);
        if (res.role === "A") {
          navigate("/admin-dashboard");
        } else {
          navigate("/upload-track");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const handleLoginSuccess = (res: any) => {
    const decoded: DecodedToken = jwtDecode(res.credential);
    const data = {
      email: decoded.email,
    };
    loginWithEmail(data)
      .then((res) => {
        sessionStorage.setItem("token", res.token);
        sessionStorage.setItem("userId", res._id);
        sessionStorage.setItem("role", res.role);
        if (res.role === "A") {
          navigate("/admin-dashboard");
        } else {
          navigate("/upload-track");
        }
      })
      .catch((err) => {
        if (err.response.status === 500) {
          toast.error("Please Signup using your email account!!");
        } else {
          toast.error(err.response.data.message);
        }
      });
  };

  const handleError = (err: any) => {
    console.log("Login Error:", err);
  };

  return (
    <>
      <ToastContainer />
      <div className="loginContainer">
        <div className="title">
          <h1 className="welcome_text">Welcome Back</h1>
          <p className="text">
            Don't have an account?{" "}
            <Link to="/signup">
              <span>SignUp</span>
            </Link>
          </p>
        </div>

        <form onSubmit={handleSubmit} className="layout">
          <input
            type="text"
            placeholder="Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
          <div className="password-wrapper">
            <input
              type={isHide ? "password" : "text"}
              placeholder="Password"
              name="password"
              value={formData.password}
              onChange={handleChange}
            />
            <span
              className="hide_password"
              onClick={() => {
                setIsHide(!isHide);
              }}
            >
              {!isHide ? <VisibilityOff /> : <Visibility />}
            </span>
          </div>
          <Link to="/forgot-password">
            <label htmlFor="forgotPassword">Forgot Password?</label>
          </Link>
          <button className="login-btn">Login</button>
        </form>

        <div className="line_break">
          <hr />
          <span className="hide_password">or</span>
        </div>

        <div className="footer">
          <GoogleLogin
            onSuccess={handleLoginSuccess}
            onError={() => handleError}
          />
        </div>
      </div>
    </>
  );
};

export default Login;
